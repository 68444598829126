<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="this.pageTitle"
      :subtitle="this.pageSubTitle"
      :hide-buttons="true"
    >
      <vs-divider class="vx-col w-full mt-5" position="left" color="light">Datos Generales</vs-divider>
      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="title" class="w-full select-large">Título</label>
          <vs-input
            id="title" name="title" v-model="entryModel.settings.title" v-validate="'required'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("title") }}</span>
          <br/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="description" class="w-full select-large">Descripción</label>
          <editor
            id="description" name="description"
            :content.sync="entryModel.settings.description.html" :resourceType="resource"
          />
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mb-5">
          <vs-divider class="vx-col text-right" position="right" color="light"><b>Desktop</b></vs-divider>
          <uploader
            :classFile="'file-upload-1'"
            :maxNumberOfFiles="1"
            @files-removed="removedFiles('desktop')"
            @files-updated="desktopUpload"
            :downloadedFiles="entryModel.settings.desktop"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full mb-5">
          <vs-divider class="vx-col text-right" position="right" color="light"><b>Mobile</b></vs-divider>
          <uploader
            :classFile="'file-upload-2'"
            :maxNumberOfFiles="1"
            @files-removed="removedFiles('mobile')"
            @files-updated="mobileUpload"
            :downloadedFiles="entryModel.settings.mobile"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="backToMediaObjectsList">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateEntry">Guardar</vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import Upload from "./../../utils/upload";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import Editor from "./../../components/Editor.vue";
  import Uploader from "./../../components/Uploader.vue";
  import {Validator} from "vee-validate";

  let notifier = new Notifier();
  const ENTRY_CLIENT = new EntriesClient("media-objects");
  const UPLOAD = new Upload("resource");

  let requiredGeneralDataFields = ["name", "description"];
  let requiredGeneralDataFieldsCustomMessages = requiredGeneralDataFields.reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = {required: "* Este campo es requerido"};
      return previousValue
    },
    {}
  );
  Validator.localize("es", {custom: requiredGeneralDataFieldsCustomMessages});
  const institutionsMapper = {
    bank: {
      id: "bank",
      code: "banco",
      name: "Banco",
      slug: "banco"
    },
    companies: {
      id: "companies",
      code: "empresas",
      name: "Empresas",
      slug: "empresa"
    },
    insurances: {
      id: "insurances",
      code: "seguros",
      name: "Seguros",
      slug: "seguros"
    },
  }

  export default {
    data() {
      return {
        pageTitle: "Banner",
        pageSubTitle: "Formulario de creación y actualización de banners",
        resource: "media-objects",
        institutions: [],
        entryModel: {
          name: "Banner",
          institution: {},
          type: this.$route.params.type,
          settings: {
            title: null,
            description: {
              html: null
            },
            mobile: {
              url: null,
              meta: {
                title: null,
                description: null,
              },
            },
            desktop: {
              url: null,
              meta: {
                title: null,
                description: null,
              },
            },
          },
          approved: true,
          priority: 1,
          publish_at: null,
          expire_at: null,
        },
      };
    },

    mounted() {
      this.entryModel.institution = institutionsMapper[this.$route.params.institution]
      this.$vs.loading();
      this.loadEntryData()
        .then(() => {
          this.$vs.loading.close();
        });
    },

    methods: {
      async loadEntryData() {
        await ENTRY_CLIENT.all({
          queryStrings: {type: this.$route.params.type, institution_id: this.$route.params.institution}
        })
          .then(response => {
            if (response.data) {
              Object.assign(this.entryModel, response.data[0]);
            }
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async createOrUpdateEntry() {
        this.$vs.loading();
        this.cleanModel();

        if (this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
          await ENTRY_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.backToMediaObjectsList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        } else {
          await ENTRY_CLIENT.create({data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.backToMediaObjectsList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      async desktopUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          this.$vs.loading();
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.settings.desktop.url = response;
              this.entryModel.settings.desktop.meta.title = files[0].meta.name;
              this.entryModel.settings.desktop.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
          this.$vs.loading.close();
        }
      },

      async mobileUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          this.$vs.loading();
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.settings.mobile.url = response;
              this.entryModel.settings.mobile.meta.title = files[0].meta.name;
              this.entryModel.settings.mobile.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
          this.$vs.loading.close();
        }
      },

      async removedFiles(file) {
        this.entryModel.settings[file] = {
          url: null,
          meta: {
            title: null,
            description: null
          }
        };
      },

      cleanModel() {
        Object.keys(this.entryModel).forEach(key => {
          if (this.entryModel[key] === null || this.entryModel[key] === "") {
            delete this.entryModel[key];
          }
        })
      },

      backToMediaObjectsList() {
        this.$router.push({name: "media-objects"});
      },
    },
    components: {
      Editor,
      FormWizard,
      TabContent,
      Uploader,
    }
  };
</script>